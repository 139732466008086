<template>
  <div class="dots-container">
    <div v-for="(dot, index) in dots" :key="index" class="dot"></div>
  </div>
</template>

<script>
export default {
  name: "DotsComponent",
  data() {
    return {
      dots: Array(25).fill(null),
    };
  },
};
</script>

<style scoped>
.dots-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  width: 250px;
  height: 250px;
  gap: 20px;
  position: relative;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #40b8ff;
  border-radius: 50%;
  margin: auto;
  opacity: 0.7;
}

@media (max-width: 1024px) {
  .dots-container {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 850px) {
  .dots-container {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 650px) {
  .dots-container {
    width: 100px;
    height: 100px;
  }
}
</style>
