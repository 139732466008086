<template>
  <div class="mobile-header">
    <a href="https://united.ba/">
      <img class="logo" src="@/assets/logo.png" alt="logo" />
    </a>
    <div class="navigation">
      <div
        v-for="(item, index) in navigationItems"
        :key="index"
        :class="['text', { active: isActive(index) }]"
      >
        <template v-if="index < sectionIds.length">
          <span @click="handleNavigation(index)" class="no-decoration">
            {{ item }}
          </span>
        </template>
        <template v-else>
          <router-link
            :to="routes[index - sectionIds.length]"
            class="no-decoration"
            @click="closeMobileHeader"
          >
            {{ item }}
          </router-link>
        </template>
      </div>
    </div>
    <img
      class="close"
      src="@/assets/close.png"
      alt="close"
      @click="closeMobileHeader"
    />
  </div>
</template>

<script>
export default {
  name: "MobileHeader",
  emits: ["close"],
  data() {
    return {
      navigationItems: [
        "What We Do",
        "Our Mission",
        "Our Impact",
        "Our Goal",
        "Achievements",
        "Contact",
      ],
      sectionIds: ["whatWeDo", "ourMission", "ourImpact", "ourGoal"],
      routes: ["/achievements", "/contact"],
      activeSection: null,
    };
  },
  computed: {
    activeRoute() {
      return this.$route.path;
    },
  },
  mounted() {
    this.observeSections();
  },
  methods: {
    handleNavigation(index) {
      if (this.$route.path !== "/") {
        this.$router.push("/").then(() => {
          this.$nextTick(() => {
            this.scrollToSection(index);
            this.closeMobileHeader();
          });
        });
      } else {
        this.scrollToSection(index);
        this.closeMobileHeader();
      }
    },
    scrollToSection(index) {
      const sectionId = this.sectionIds[index];
      this.$nextTick(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    closeMobileHeader() {
      this.$emit("close");
    },
    observeSections() {
      const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.activeSection = entry.target.id;
          }
        });
      }, observerOptions);

      this.sectionIds.forEach((id) => {
        const section = document.getElementById(id);
        if (section) {
          observer.observe(section);
        }
      });
    },
    isActive(index) {
      if (index < this.sectionIds.length) {
        return this.activeSection === this.sectionIds[index];
      } else {
        return this.activeRoute === this.routes[index - this.sectionIds.length];
      }
    },
  },
};
</script>

<style scoped>
.mobile-header {
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.logo {
  width: 40px;
}
.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.text {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #292c47;
  transition: color 0.3s ease-in-out;
  margin-top: 10px;
}
.text.active {
  color: #00bf63;
}
.no-decoration {
  text-decoration: none;
  color: inherit;
}
.no-decoration:hover {
  text-decoration: none;
}
.close {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
