<template>
  <body class="body-goal" id="ourGoal">
    <div class="left-side-body">
      <div class="title">Our Goal</div>
      <div class="line" />
      <div
        v-for="(section, index) in approachSections"
        :key="index"
        class="text-box"
      >
        <div class="number-wrapper">
          <div :class="['number', { 'alt-number': index === 1 }]">
            {{ section.number }}
          </div>
        </div>
        <div class="text-wrapper">
          <div :class="['text', { 'alt-text': index === 1 }]">
            {{ section.title }}
          </div>
          <div class="subtext">
            {{ section.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="right-side-body">
      <img
        class="goal-image"
        src="../../assets/OurGoal/OurGoal.png"
        alt="our goal"
      />
      <div class="icons-wrapper">
        <img
          class="image"
          src="../../assets/OurGoal/checked.png"
          alt="checked"
        />
        <img class="image" src="../../assets/OurGoal/star.png" alt="star" />
        <img class="image" src="../../assets/OurGoal/search.png" alt="search" />
      </div>
    </div>
    <div class="circle" />
    <DotsComponent class="dots" />
  </body>
</template>

<script>
import DotsComponent from "../SharedComponents/DotsComponent.vue";

export default {
  name: "OurGoal",
  components: {
    DotsComponent,
  },
  data() {
    return {
      approachSections: [
        {
          number: "1",
          title: "STRONGER COMMUNITIES",
          text: "By fostering unity among children from various backgrounds, we aim to create stronger, more inclusive communities.",
        },
        {
          number: "2",
          title: "HEALTHIER LIFESTYLE",
          text: "Children who engage in regular physical activities develop healthier habits, which lead to improved physical and mental well-being.",
        },
        {
          number: "3",
          title: "EMPOWERED CHILDREN",
          text: "We aim to build confidence, self-esteem, and a sense of belonging in every child, empowering them to pursue their dreams, both in sports and in life.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.body-goal {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  position: relative;
  height: fit-content;
  padding: 0px 40px;
  box-sizing: border-box;
}
.left-side-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 30px;
  box-sizing: border-box;
  gap: 30px;
  width: 50%;
  position: relative;
  z-index: 3;
}
.title {
  font-size: 72px;
  color: #737373;
  font-weight: 600;
  line-height: 1;
  max-width: 600px;
  text-align: left;
}
.text-box {
  display: flex;
  gap: 20px;
}
.number-wrapper {
  width: 40px;
  height: 100%;
}
.number {
  width: 40px;
  height: 40px;
  background-color: #40b8ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  font-weight: 600;
}

.alt-number {
  background-color: #00bf63;
}

.text {
  font-size: 20px;
  color: #40b8ff;
  font-weight: 600;
}

.alt-text {
  color: #00bf63;
}

.subtext {
  font-size: 18px;
  line-height: 1.3;
}
.right-side-body {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.goal-image {
  max-width: 500px;
}

.icons-wrapper {
  display: flex;
  gap: 20px;
  height: 70px;
}
.image {
  height: 60px;
}
.circle {
  width: 200px;
  height: 200px;
  border: 40px solid #00bf63;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: -150px;
  left: -150px;
}

.line {
  border: 2px solid #00bf63;
  width: 80px;
}

.dots {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1240px) {
  .title {
    font-size: 62px;
  }
}

@media (max-width: 1024px) {
  .body-goal {
    flex-direction: column;
    gap: 20px;
  }
  .left-side-body,
  .right-side-body {
    width: 100%;
    height: fit-content;
  }
  .left-side-body {
    padding: 0px;
    gap: 20px;
  }
  .dots {
    display: none;
  }
  .number {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
  .title {
    font-size: 52px;
  }
  .text,
  .subtext {
    font-size: 16px;
  }
  .goal-image {
    max-width: 400px;
  }
  .image {
    height: 50px;
  }
  .circle {
    width: 150px;
    height: 150px;
    bottom: -120px;
    left: -120px;
    border: 30px solid #00bf63;
  }
}

@media (max-width: 850px) {
  .body-goal {
    gap: 10px;
  }
  .number {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
  .title {
    font-size: 42px;
  }
  .text,
  .subtext {
    font-size: 14px;
  }
  .goal-image {
    max-width: 300px;
  }
  .image {
    height: 40px;
  }
  .circle {
    width: 100px;
    height: 100px;
    bottom: -80px;
    left: -80px;
    border: 20px solid #00bf63;
  }
}
@media (max-width: 650px) {
  .body-goal {
    padding: 0px 20px;
  }
  .number {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
  .text,
  .subtext {
    font-size: 12px;
  }
  .goal-image {
    max-width: 250px;
  }
  .image {
    height: 30px;
  }
}
</style>
