<template>
  <body>
    <Herosection />
    <WhatWeDo />
    <OurMission />
    <OurApproach />
    <OurImpact />
    <OurGoal />
    <LearningValues />
    <ConclusionAndCall />
    <AppFooter />
  </body>
</template>

<script>
import Herosection from "../components/HeroSection/Herosection.vue";
import WhatWeDo from "../components/WhatWeDo/WhatWeDo.vue";
import OurMission from "../components/OurMission/OurMission.vue";
import OurApproach from "../components/OurApproach/OurApproach.vue";
import OurImpact from "../components/OurImpact/OurImpact.vue";
import OurGoal from "../components/OurGoal/OurGoal.vue";
import LearningValues from "../components/LearningValues/LearningValues.vue";
import ConclusionAndCall from "@/components/ConclusionAndCall/ConclusionAndCall.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";

export default {
  name: "HomePage",
  components: {
    Herosection,
    WhatWeDo,
    OurMission,
    OurApproach,
    OurImpact,
    OurGoal,
    LearningValues,
    AppFooter,
    ConclusionAndCall,
  },
};
</script>

<style scoped>
body {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (max-width: 850px) {
  body {
    gap: 20px;
  }
}
</style>
