<template>
  <div class="whatwedo-body" id="whatWeDo">
    <div class="left-side-body">
      <div class="program-wrapper">
        <img
          class="program-img"
          src="../../assets/WhatWeDo/program.png"
          alt="program"
        />
      </div>
      <div class="training-wrapper">
        <img
          class="training-img"
          src="../../assets/WhatWeDo/training.png"
          alt="training"
        />
      </div>
      <div class="physical-wrapper">
        <img
          class="physical-img"
          src="../../assets/WhatWeDo/physical-education.jpg"
          alt="education"
        />
      </div>
    </div>
    <div class="right-side-body">
      <div class="title">What We Do</div>
      <div class="sub-title">KEY PROGRAMS</div>
      <div class="line"></div>
      <div
        v-for="(section, index) in sections"
        :key="index"
        class="section-wrapper"
      >
        <div class="section-title">{{ section.title }}</div>
        <div class="section-text">{{ section.text }}</div>
      </div>
    </div>
    <DotsComponent class="dots" />
    <div class="circle" />
  </div>
</template>

<script>
import DotsComponent from "../SharedComponents/DotsComponent.vue";

export default {
  name: "WhatWeDo",
  components: {
    DotsComponent,
  },
  data() {
    return {
      sections: [
        {
          title: "1. Sports Education Programs",
          text: "We organize regular sports activities for children in care to develop motor and cognitive skills. These programs include basic skill training in various sports such as football, basketball, and athletics.",
        },
        {
          title: "2. Inclusive Workshops",
          text: "Our workshops are designed to bring together children from diverse backgrounds—those with parental care, without it, and those facing difficulties—teaching them teamwork, communication, and leadership through sports.",
        },
        {
          title: "3. Life Skills Through Play",
          text: "We focus on teaching life skills like problem-solving, resilience, and discipline through structured games and physical activities. Children learn essential values such as respect, empathy, and cooperation in a fun, engaging environment.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.whatwedo-body {
  height: fit-content;
  display: flex;
  position: relative;
  z-index: 3;
  padding: 60px 40px 0px 40px;
  box-sizing: border-box;
}
.left-side-body {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  position: relative;
  z-index: 2;
}

.program-wrapper,
.training-wrapper,
.physical-wrapper {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.program-wrapper {
  width: 350px;
  height: 350px;
}
.training-wrapper,
.physical-wrapper {
  width: 250px;
  height: 250px;
}
.training-wrapper {
  position: absolute;
  bottom: 24%;
  left: 45%;
}
.physical-wrapper {
  margin: 0px 0px 40px 60px;
}

.program-img,
.training-img,
.physical-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right-side-body {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  gap: 10px;
  padding: 0px 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}
.title {
  font-size: 72px;
  font-weight: 600;
  color: #737373;
  line-height: 1;
}
.sub-title {
  font-size: 18px;
}
.line {
  width: 70px;
  border: 2px solid #00bf63;
}
.section-wrapper {
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 10px;
}
.section-title {
  font-size: 18px;
  font-weight: 600;
  color: #40b8ff;
}
.dots {
  position: absolute;
  top: 0;
  right: 50%;
}
.circle {
  width: 200px;
  height: 200px;
  border: 40px solid #00bf63;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: -150px;
  left: -150px;
}

@media (max-width: 1240px) {
  .program-wrapper {
    width: 300px;
    height: 300px;
  }
  .training-wrapper,
  .physical-wrapper {
    width: 200px;
    height: 200px;
  }
  .title {
    font-size: 62px;
  }
}
@media (max-width: 1024px) {
  .whatwedo-body {
    flex-direction: column;
    gap: 30px;
    padding: 40px 20px;
  }
  .left-side-body {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
  }
  .program-wrapper {
    width: 250px;
    height: 250px;
  }
  .training-wrapper,
  .physical-wrapper {
    display: none;
  }
  .right-side-body {
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
  }
  .section-wrapper {
    text-align: left;
  }
  .circle {
    width: 150px;
    height: 150px;
    bottom: -120px;
    left: -120px;
    border: 30px solid #00bf63;
  }
  .title {
    font-size: 52px;
  }
  .sub-title,
  .section-title,
  .section-wrapper {
    font-size: 16px;
  }
}
@media (max-width: 850px) {
  .whatwedo-body {
    gap: 20px;
    padding: 20px 20px;
  }
  .program-wrapper {
    width: 200px;
    height: 200px;
  }
  .section-wrapper {
    text-align: left;
  }
  .circle {
    width: 100px;
    height: 100px;
    bottom: -80px;
    left: -80px;
    border: 20px solid #00bf63;
  }
  .sub-title,
  .section-title,
  .section-wrapper {
    font-size: 14px;
  }
}
@media (max-width: 650px) {
  .whatwedo-body {
    gap: 20px;
    padding: 20px 10px;
  }
  .program-wrapper {
    width: 150px;
    height: 150px;
  }
  .title {
    font-size: 42px;
  }
  .sub-title,
  .section-title,
  .section-wrapper {
    font-size: 12px;
  }
}
</style>
