<template>
  <body class="body-mission">
    <div class="left-side-body">
      <div class="title">Conclusion and Call to Action</div>
      <div class="line" />
      <div class="text">
        <span class="united">UNITED </span> is more than a sports organization –
        it is a platform for children's development and breaking down barriers
        between them. <span class="support">Your support</span> can help this
        organization create lasting change, connect children, and provide them
        with opportunities for a better future.
      </div>
      <div class="button-wrapper">
        <div class="button" @click="goToContact">JOIN US</div>
      </div>
    </div>
    <div class="right-side-body">
      <DotsComponent class="dots" />
      <div class="curved-image"></div>
      <svg class="half-circle" width="600" height="250" viewBox="0 0 600 250">
        <path d="M 0 250 Q 300 -50 600 250 Z" fill="#030929" />
      </svg>
    </div>
    <div class="circle" />
  </body>
</template>

<script>
import DotsComponent from "../SharedComponents/DotsComponent.vue";

export default {
  name: "ConclusionAndCall",
  components: {
    DotsComponent,
  },
  methods: {
    goToContact() {
      this.$router.push("/contact");
    },
  },
};
</script>

<style scoped>
.body-mission {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 40px 40px 0px 40px;
  box-sizing: border-box;
  flex-direction: row-reverse;
  height: fit-content;
}
.left-side-body {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  gap: 30px;
}
.title {
  font-size: 72px;
  color: #737373;
  font-weight: 600;
  line-height: 1;
  max-width: 600px;
}
.line {
  border: 2px solid #00bf63;
  width: 80px;
}

.text {
  font-size: 20px;
  color: #737373;
  font-weight: 600;
}
.united {
  color: #00bf63;
}
.support {
  color: #40b8ff;
}

.right-side-body {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.half-circle {
  position: absolute;
  bottom: 0;
}
.dots {
  position: absolute;
  top: -20%;
}
.curved-image {
  position: relative;
  z-index: 2;
  border-radius: 50% 50% 0% 0%;
  width: 400px;
  height: 430px;
  background-image: url("../../assets/call-to-action.jpg");
  background-position: 40% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 70px;
}
.circle {
  width: 200px;
  height: 200px;
  border: 40px solid #030929;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: -200px;
  left: -150px;
}

.button {
  width: 151px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  border-radius: 30px;
  color: #ffffff;
  background-color: #00bf63;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
.button:hover {
  background-color: #137847;
}
@media (max-width: 1240px) {
  .body-mission {
    height: fit-content;
  }
  .title {
    font-size: 62px;
  }
  .button {
    width: 121px;
    height: 40px;
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .body-mission {
    display: flex;
    flex-direction: column-reverse;
    gap: 0px;
  }
  .left-side-body {
    height: 50%;
    width: 100%;
    padding: 20px 0px;
    gap: 20px;
  }
  .title {
    font-size: 52px;
  }
  .number {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
  .text,
  .subtext {
    font-size: 16px;
  }
  .right-side-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }
  .curved-image {
    width: 300px;
    height: 330px;
  }
  .half-circle {
    transform: scale(0.8);
  }
  .dots {
    display: none;
  }
  .circle {
    width: 150px;
    height: 150px;
    border: 30px solid #030929;
    bottom: -150px;
    left: -150px;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 850px) {
  .left-side-body {
    gap: 20px;
  }
  .number {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
  .text,
  .subtext {
    font-size: 14px;
  }
  .curved-image {
    width: 250px;
    height: 280px;
    margin-bottom: 20px;
  }
  .half-circle {
    transform: scale(0.6);
    bottom: -50px;
  }
  .circle {
    width: 100px;
    height: 100px;
    border: 20px solid #030929;
    bottom: -90px;
    left: -90px;
  }
  .button {
    width: 100px;
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  .body-mission {
    padding: 0px 20px;
  }
  .left-side-body {
    gap: 10px;
  }
  .title {
    font-size: 42px;
  }
  .number {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
  .text,
  .subtext {
    font-size: 12px;
  }
  .curved-image {
    width: 150px;
    height: 180px;
  }
  .half-circle {
    transform: scale(0.4);
    bottom: -70px;
  }
}
</style>
