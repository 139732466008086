<template>
  <div class="hero-section-body">
    <AppHeader />
    <div class="hero-section">
      <div class="text">World Champions 2024</div>
    </div>
    <div class="circle" />
  </div>
  <AppAchievements />
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/SharedComponents/AppHeader.vue";
import AppAchievements from "@/components/Achievements/AppAchievements.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";

export default {
  name: "HeroSection",
  components: {
    AppHeader,
    AppAchievements,
    AppFooter,
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Sports World";
  src: url("@/assets/fonts/SportsWorld.ttf") format("truetype");
}

.hero-section-body {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("../assets/Achievements/world-champions.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
}

.hero-section-body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.hero-section {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 3;
  justify-content: center;
  margin-bottom: 40px;
}

.text {
  font-size: 75px;
  text-align: left;
  color: white;
  font-weight: 600;
  text-shadow: #00bf63 1px 0 10px;
  text-align: center;
}

.circle {
  width: 200px;
  height: 200px;
  border: 40px solid #00bf63;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: -150px;
  right: -150px;
}
@media (max-width: 1240px) {
  .text {
    font-size: 65px;
  }
}
@media (max-width: 1024px) {
  .circle {
    width: 150px;
    height: 150px;
    bottom: -120px;
    right: -120px;
    border: 30px solid #00bf63;
  }
}
@media (max-width: 850px) {
  .text {
    font-size: 55px;
  }
  .circle {
    width: 100px;
    height: 100px;
    bottom: -80px;
    right: -80px;
    border: 20px solid #00bf63;
  }
}
@media (max-width: 650px) {
  .text {
    font-size: 45px;
  }
}
</style>
