<template>
  <body class="body-mission">
    <div class="left-side-body">
      <div class="title">Our Unique Approach</div>
      <div class="line" />
      <div
        v-for="(section, index) in approachSections"
        :key="index"
        class="text-box"
      >
        <div class="number-wrapper">
          <div :class="['number', { 'alt-number': index === 1 }]">
            {{ section.number }}
          </div>
        </div>
        <div class="text-wrapper">
          <div :class="['text', { 'alt-text': index === 1 }]">
            {{ section.title }}
          </div>
          <div class="subtext">
            {{ section.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="right-side-body">
      <DotsComponent class="dots" />
      <div class="curved-image"></div>
      <svg class="half-circle" width="600" height="250" viewBox="0 0 600 250">
        <path d="M 0 250 Q 300 -50 600 250 Z" fill="#00bf63" />
      </svg>
    </div>
  </body>
</template>

<script>
import DotsComponent from "../SharedComponents/DotsComponent.vue";

export default {
  name: "OurApproach",
  components: {
    DotsComponent,
  },
  data() {
    return {
      approachSections: [
        {
          number: "1",
          title: "PERSONALIZED DEVELOPMENT",
          text: "Every child has unique strengths. We tailor our programs to nurture both physical and intellectual skills, giving personalized attention to each participant to help them thrive.",
        },
        {
          number: "2",
          title: "NO CHILD LEFT BEHIND",
          text: "We believe in equal opportunities for all children. Our programs ensure that every child, regardless of their background, has access to sports and education.",
        },
        {
          number: "3",
          title: "LONG TERM ENGAGEMENT",
          text: "UNITED is committed to creating lasting relationships with the children, offering them continuous support and follow-up programs to track their progress over time.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.body-mission {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 0px 40px;
  box-sizing: border-box;
  flex-direction: row-reverse;
}
.left-side-body {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 90px 30px 0px 30px;
  box-sizing: border-box;
  gap: 30px;
}
.title {
  font-size: 72px;
  color: #737373;
  font-weight: 600;
  line-height: 1;
  max-width: 600px;
}
.line {
  border: 2px solid #00bf63;
  width: 80px;
}

.text-box {
  display: flex;
  gap: 20px;
}
.number-wrapper {
  width: 40px;
  height: 100%;
}
.number {
  width: 40px;
  height: 40px;
  background-color: #40b8ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  font-weight: 600;
}

.alt-number {
  background-color: #00bf63;
}

.text {
  font-size: 20px;
  color: #40b8ff;
  font-weight: 600;
}

.alt-text {
  color: #00bf63;
}

.subtext {
  font-size: 18px;
  line-height: 1.3;
}
span {
  font-weight: 600;
}
.span-two {
  color: #40b8ff;
  text-decoration: underline;
}
.right-side-body {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.half-circle {
  position: absolute;
  bottom: 0;
}
.dots {
  position: absolute;
  top: 10%;
}
.curved-image {
  position: relative;
  z-index: 2;
  border-radius: 50% 50% 0% 0%;
  width: 400px;
  height: 430px;
  background-image: url("../../assets/OurApproach/approach.jpg");
  background-position: 70% 20%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 70px;
}

@media (max-width: 1240px) {
  .body-mission {
    height: fit-content;
  }
  .title {
    font-size: 62px;
  }
}
@media (max-width: 1024px) {
  .body-mission {
    display: flex;
    flex-direction: column-reverse;
    gap: 0px;
  }
  .left-side-body {
    height: 50%;
    width: 100%;
    padding: 20px 0px;
    gap: 20px;
  }
  .title {
    font-size: 52px;
  }
  .number {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
  .text,
  .subtext {
    font-size: 16px;
  }
  .right-side-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }
  .curved-image {
    width: 300px;
    height: 330px;
  }
  .half-circle {
    transform: scale(0.8);
  }
  .dots {
    display: none;
  }
}
@media (max-width: 850px) {
  .left-side-body {
    gap: 20px;
  }
  .number {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
  .text,
  .subtext {
    font-size: 14px;
  }
  .curved-image {
    width: 250px;
    height: 280px;
    margin-bottom: 20px;
  }
  .half-circle {
    transform: scale(0.6);
    bottom: -50px;
  }
}
@media (max-width: 650px) {
  .body-mission {
    padding: 0px 20px;
  }
  .left-side-body {
    gap: 10px;
  }
  .title {
    font-size: 42px;
  }
  .number {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
  .text,
  .subtext {
    font-size: 12px;
  }
  .curved-image {
    width: 150px;
    height: 180px;
  }
  .half-circle {
    transform: scale(0.4);
    bottom: -70px;
  }
}
</style>
