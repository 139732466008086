<template>
  <div class="contact-form-content">
    <div class="title-wrapper">
      <div class="title">Contact us</div>
      <div class="line" />
    </div>

    <div class="form-container">
      <div class="form-wrapper">
        <input
          type="text"
          v-model="name"
          placeholder="Your Name"
          class="form-input"
          required
        />
        <input
          type="email"
          v-model="email"
          placeholder="Your Email"
          class="form-input"
          required
        />
        <textarea
          v-model="message"
          placeholder="Your Message"
          class="form-textarea"
          required
        ></textarea>
        <button @click="submitForm" class="green-box">Send Message</button>
      </div>
      <transition name="fade">
        <div v-if="showPopup" class="popup">
          {{ popupMessage }}
        </div>
      </transition>

      <div class="location-box">
        <div id="map"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      showPopup: false,
      popupMessage: "",
      map: null,
    };
  },
  mounted() {
    this.loadGoogleMaps();
  },
  methods: {
    async submitForm() {
      if (!this.name || !this.email || !this.message) {
        this.showPopupMessage("Please fill in all fields.");
        return;
      }
      if (!this.validateEmail(this.email)) {
        this.showPopupMessage("Please enter a valid email address.");
        return;
      }

      try {
        const response = await fetch("https://formspree.io/f/xyzkdjpb", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            message: this.message,
          }),
        });

        if (response.ok) {
          this.showPopupMessage("Message sent successfully!");
          this.name = "";
          this.email = "";
          this.message = "";
        } else {
          this.showPopupMessage("Something went wrong. Please try again.");
        }
      } catch (error) {
        this.showPopupMessage("Error sending message.");
      }
    },
    validateEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    },
    showPopupMessage(message) {
      this.popupMessage = message;
      this.showPopup = true;
      setTimeout(() => {
        this.showPopup = false;
      }, 3000);
    },
    loadGoogleMaps() {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCOU95grwUkqcAQpiIpCh-8wm-9yrQlzXo`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          this.initMap();
        };
        document.head.appendChild(script);
      } else {
        this.initMap();
      }
    },
    initMap() {
      const mapElement = document.getElementById("map");
      if (!mapElement) {
        console.error("Map element not found!");
        return;
      }

      const location = { lat: 43.855700631323025, lng: 18.419674183805075 };

      this.map = new window.google.maps.Map(mapElement, {
        center: location,
        zoom: 15,
      });

      new window.google.maps.Marker({
        position: location,
        map: this.map,
        title: "United Association - Skenderija 31",
      });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup {
  position: absolute;
  bottom: 10px; /* Postavlja ga na dno forme */
  left: 10px; /* Postavlja ga u lijevi ugao */
  background-color: #00bf63;
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  opacity: 0.95;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 50px;
}
.title {
  font-size: 50px;
  color: #737373;
  font-weight: 600;
  line-height: 1;
  text-align: center;
}
.line {
  border: 2px solid #00bf63;
  width: 180px;
}
.title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.form-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 100%;
  max-width: 1200px;
  height: fit-content;
}
.form-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  width: 50%;
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  box-sizing: border-box;
  border: 2px solid #00bf63;
  border-radius: 10px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: #00bf63 1px 0 10px;
}
.form-textarea {
  min-height: 120px;
  resize: none;
}
.green-box {
  background-color: #00bf63;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 15px 20px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border: none;
  max-width: 400px;
}
.green-box:hover {
  background-color: #008f4c;
}
.location-box {
  width: 50%;
  background-color: #f5f5f5;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  text-align: center;
  flex: 1;
  box-shadow: #030929 1px 0 10px;
}

#map {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

@media (max-width: 1024px) {
  .title {
    font-size: 40px;
  }
  .contact-form-content {
    padding: 20px 30px;
    gap: 30px;
  }
  .form-container {
    gap: 30px;
  }
}
@media (max-width: 850px) {
  .form-container {
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .form-wrapper {
    gap: 15px;
    width: 100%;
    height: 50%;
  }
  .form-textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }
  .form-textarea {
    min-height: 100px;
  }
  .green-box {
    padding: 10px 15px;
    font-size: 16px;
  }
  .location-box {
    width: 100%;
    min-height: 250px;
    height: auto;
  }
  #map {
    height: 250px;
  }
  .popup {
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
    font-size: 12px;
  }
}
</style>
