import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import OurTeam from "@/pages/OurTeamPage.vue";
import Achievements from "@/pages/AchievementsPage.vue";
import Contact from "@/pages/ContactPage.vue";
import AppDonations from "@/pages/AppDonations.vue";

const routes = [
  { path: "/", component: HomePage }, 
  { path: "/our-team", component: OurTeam },
  { path: "/achievements", component: Achievements },
  { path: "/contact", component: Contact },
  { path: "/:pathMatch(.*)*", redirect: "/" }, 
  { path: "/donations", component: AppDonations }, 

];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0};
  },
});


export default router;
