<template>
  <body class="body-mission" id="ourImpact">
    <div class="left-side-body">
      <div class="title">How We Impact the Community</div>
      <div
        v-for="(section, index) in approachSections"
        :key="index"
        class="text-box"
      >
        <div class="number-wrapper">
          <div :class="['number', { 'alt-number': index === 1 }]">
            {{ section.number }}
          </div>
        </div>
        <div class="text-wrapper">
          <div :class="['text', { 'alt-text': index === 1 }]">
            {{ section.title }}
          </div>
          <div class="subtext">
            {{ section.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="right-side-body">
      <div class="impact-container">
        <div class="image-wrapper"></div>
      </div>
    </div>
    <div class="circle" />
  </body>
</template>

<script>
export default {
  name: "OurImpact",
  data() {
    return {
      approachSections: [
        {
          number: "1",
          title: "BUILDING BRIDGES",
          text: "By connecting children from different social backgrounds, we break down barriers and reduce prejudice. We foster understanding, tolerance, and unity, helping children grow as compassionate and open-minded individuals.",
        },
        {
          number: "2",
          title: "CREATING FUTURE LEADERS",
          text: "Through our mentorship programs, we provide role models and leaders from the local community to guide and support the children in both sports and life, helping them become future leaders.",
        },
        {
          number: "3",
          title: "POSITIVE SOCIAL CHANGE",
          text: "Our programs address social issues such as bullying, discrimination, and hate speech by teaching children to respect differences and work together as a team.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.body-mission {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: fit-content;
  padding: 0px 40px;
  box-sizing: border-box;
}
.left-side-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 30px;
  box-sizing: border-box;
  gap: 30px;
  width: 60%;
  position: relative;
  z-index: 3;
}
.title {
  font-size: 72px;
  color: #737373;
  font-weight: 600;
  line-height: 1;
  max-width: 600px;
  text-align: left;
}
.text-box {
  display: flex;
  gap: 20px;
}
.number-wrapper {
  width: 40px;
  height: 100%;
}
.number {
  width: 40px;
  height: 40px;
  background-color: #40b8ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  font-weight: 600;
}

.alt-number {
  background-color: #00bf63;
}

.text {
  font-size: 20px;
  color: #40b8ff;
  font-weight: 600;
}

.alt-text {
  color: #00bf63;
}

.subtext {
  font-size: 18px;
  line-height: 1.3;
}
.right-side-body {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.impact-container {
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  overflow: hidden;
  border-left: 30px solid #40b8ff;
  border-bottom: 30px solid #40b8ff;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/OurImpact/impact.png");
  background-position: 50% 20%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid;
}
.circle {
  width: 200px;
  height: 200px;
  border: 40px solid #00bf63;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: -150px;
  right: -150px;
}

@media (max-width: 1240px) {
  .title {
    font-size: 62px;
    color: #737373;
  }
  .impact-container {
    width: 350px;
    height: 350px;
    border-left: 25px solid #40b8ff;
    border-bottom: 25px solid #40b8ff;
  }
}
@media (max-width: 1024px) {
  .body-mission {
    display: flex;
    flex-direction: column-reverse;
  }
  .left-side-body {
    height: fit-content;
    width: 100%;
    padding: 0px;
    gap: 20px;
  }
  .right-side-body {
    width: 100%;
  }
  .impact-container {
    width: 300px;
    height: 300px;
    border-left: 20px solid #40b8ff;
    border-bottom: 20px solid #40b8ff;
  }
  .number {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
  .title {
    font-size: 52px;
  }
  .text,
  .subtext {
    font-size: 16px;
  }
  .circle {
    width: 150px;
    height: 150px;
    bottom: -120px;
    right: -120px;
    border: 30px solid #00bf63;
  }
  @media (max-width: 850px) {
    .impact-container {
      width: 250px;
      height: 250px;
      border-left: 15px solid #40b8ff;
      border-bottom: 15px solid #40b8ff;
    }
    .number {
      width: 30px;
      height: 30px;
      font-size: 15px;
    }
    .title {
      font-size: 52px;
    }
    .text,
    .subtext {
      font-size: 14px;
    }
    .circle {
      width: 100px;
      height: 100px;
      bottom: -80px;
      right: -80px;
      border: 20px solid #00bf63;
    }
  }
  @media (max-width: 650px) {
    .body-mission {
      padding: 0px 20px;
    }
    .left-side-body {
      gap: 10px;
    }
    .impact-container {
      width: 200px;
      height: 200px;
      border-left: 15px solid #40b8ff;
      border-bottom: 15px solid #40b8ff;
    }
    .number {
      width: 25px;
      height: 25px;
      font-size: 12px;
    }
    .title {
      font-size: 42px;
    }
    .text,
    .subtext {
      font-size: 12px;
    }
  }
}
</style>
