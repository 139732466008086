<template>
  <div class="contact-page">
    <AppHeaderVue :isScrolled="true" :isFixed="false" />
    <div class="contact-content">
      <div class="title-wrapper">
        <div class="title">Our Partners & Supporters</div>
        <div class="line" />
      </div>
      <div class="text">
        At <span> UNITED </span>, we are grateful to have the support of
        organizations that believe in our mission and contribute to creating
        better opportunities for children through sports. <br />
        <br />
        We proudly collaborate with:
      </div>
      <div class="images-wrapper">
        <img class="image" src="../../assets/sos-djecija-sela.png" alt="sos" />
        <img class="image" src="../../assets/grawe.jpeg" alt="grawe" />
        <img class="image" src="../../assets/pomozi-ba.svg" alt="pomozi" />
        <img class="image-two" src="../../assets/ziptech.jpeg" alt="zip" />
        <img class="image-two" src="../../assets/wecobi.png" alt="wecobi" />
      </div>
      <div class="green-box">
        Join us in this mission and become a new partner or supporter in
        creating a better society for everyone.
      </div>
    </div>
    <ContactForm />
    <AppFooter />
  </div>
</template>

<script>
import AppHeaderVue from "../SharedComponents/AppHeader.vue";
import AppFooter from "../SharedComponents/AppFooter.vue";
import ContactForm from "./ContactForm.vue";

export default {
  name: "AppContact",
  components: {
    AppHeaderVue,
    AppFooter,
    ContactForm,
  },
};
</script>

<style scoped>
.contact-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.contact-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 50px;
}
.title {
  font-size: 50px;
  color: #737373;
  font-weight: 600;
  line-height: 1;
  text-align: center;
}
.line {
  border: 2px solid #00bf63;
  width: 180px;
}
.title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.text {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  max-width: 70%;
}
.images-wrapper {
  display: flex;
  gap: 40px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.image {
  height: 60px;
}
.image-two {
  height: 40px;
}

span {
  color: #00bf63;
}
.green-box {
  background-color: #00bf63;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  .contact-content {
    padding: 30px;
    gap: 30px;
  }
  .title {
    font-size: 40px;
  }
  .text {
    font-size: 16px;
  }
  .images-wrapper {
    gap: 30px;
  }
  .image {
    height: 50px;
  }
  .image-two {
    height: 30px;
  }
  .green-box {
    padding: 10px;
    font-size: 16px;
  }
}
@media (max-width: 650px) {
  .contact-content {
    padding: 20px;
    gap: 20px;
  }
  .title {
    font-size: 30px;
  }
  .text {
    font-size: 12px;
  }
  .images-wrapper {
    gap: 20px;
  }
  .image {
    height: 40px;
  }
  .image-two {
    height: 30px;
  }
  .green-box {
    padding: 10px;
    font-size: 12px;
  }
}
</style>
