<template>
  <body>
    <AppHeaderVue :isScrolled="true" :isFixed="false" />
    <div class="donations-page">
      <h1>DONATE NOW</h1>

      <div class="donation-section">
        <h2>For Donors from Bosnia and Herzegovina</h2>
        <p>
          You can donate via the WS Pay service or by making a direct payment
          through mobile banking.
        </p>
        <div class="button-container">
          <a href="#" @click.prevent="showUnavailableMessage" class="button"
            >DONATE</a
          >
        </div>

        <div class="donation-options">
          <img src="@/assets/Donations/ws-pay.png" alt="WS Pay" />
          <img
            src="@/assets/Donations/mastercar-check.png"
            alt="MasterCard Check"
          />
          <img src="@/assets/Donations/mastercard.svg" alt="MasterCard" />
          <img src="@/assets/Donations/visa-secure.webp" alt="Visa Secure" />
          <img src="@/assets/Donations/visa.png" alt="Visa" />
        </div>
        <div class="bank-transfer-info">
          <h3>Bank Transfer Instructions:</h3>
          <p><strong>Recipient:</strong> Udruženje UNITED</p>
          <p><strong>ID:</strong> 4203445630007</p>
          <p><strong>Account Number:</strong> 1341051130001233</p>
          <p><strong>Bank:</strong> ASA Banka d.d.</p>
          <p><strong>Purpose:</strong> Donation</p>
        </div>
      </div>
      <div class="donation-section">
        <h2>For Donors from Abroad</h2>
        <p>You can donate via PayPal.</p>
        <div class="button-container">
          <a href="#" @click.prevent="showUnavailableMessage" class="button"
            >DONATE</a
          >
        </div>
        <div class="donation-options">
          <img src="@/assets/Donations/paypal.png" alt="PayPal" />
        </div>
      </div>
    </div>
    <AppFooter />
  </body>
</template>

<script>
import AppHeaderVue from "@/components/SharedComponents/AppHeader.vue";
import AppFooter from "@/components/SharedComponents/AppFooter.vue";

export default {
  name: "AppDonations",
  components: {
    AppHeaderVue,
    AppFooter,
  },
  methods: {
    showUnavailableMessage() {
      alert(
        "Currently unavailable, please use direct bank transfer or contact us at info@united.ba."
      );
    },
  },
};
</script>

<style scoped>
body {
  display: flex;
  flex-direction: column;
}
.donations-page {
  text-align: center;
  padding: 0px 40px;
  color: white;
}
h1 {
  color: #00bf63;
  font-size: 28px;
}
.donation-section {
  background-color: #030929;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 30px;
  text-align: center;
}
h2 {
  color: #00bf63;
  font-size: 22px;
}
p {
  font-size: 16px;
  margin: 10px 0;
}

/* DONATE BUTTON */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.button {
  width: 151px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  border-radius: 30px;
  color: #ffffff;
  background-color: #00bf63;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  transition: background 0.3s;
}
.button:hover {
  background-color: #008c50;
}

.donation-options {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.donation-options img {
  width: auto;
  height: 20px;
  border-radius: 10px;
  background: white;
  padding: 10px;
}
.bank-transfer-info {
  text-align: left;
  background: #061049;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
}
.bank-transfer-info h3 {
  color: #00bf63;
  font-size: 18px;
}
.bank-transfer-info p {
  font-size: 14px;
  color: #ccc;
}

@media (max-width: 1024px) {
  .donation-options img {
    height: 40px;
  }
}

@media (max-width: 850px) {
  body {
    gap: 20px;
  }
  .donations-page {
    text-align: center;
    padding: 0px 20px;
    color: white;
  }
  .button {
    width: 121px;
    height: 40px;
    font-size: 14px;
  }
}
@media (max-width: 850px) {
  .donation-options img {
    height: 15px;
  }
  .button {
    width: 100px;
    font-size: 12px;
  }
}
</style>
