<template>
  <div class="hero-section-body">
    <AppHeader />
    <div class="hero-section">
      <div class="left-hero-section">
        <DotsComponent class="dots" />
        <div class="logo-border"></div>
        <div class="logo-wrapper">
          <img class="logo" src="../../assets/logo.png" alt="logo" />
        </div>
      </div>
      <div class="right-hero-section">
        <div class="special-text">UNITED</div>
        <div class="text">
          <span>Mission:</span> Connecting & Educating Children in Care Through
          Sports
        </div>
      </div>
    </div>
    <div class="circle" />
  </div>
</template>

<script>
import AppHeader from "../SharedComponents/AppHeader.vue";
import DotsComponent from "../SharedComponents/DotsComponent.vue";

export default {
  name: "HeroSection",
  components: {
    AppHeader,
    DotsComponent,
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Sports World";
  src: url("@/assets/fonts/SportsWorld.ttf") format("truetype");
}
.hero-section-body {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("../../assets/background.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-direction: column;
  gap: 20px;
}

.hero-section-body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.hero-section {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: center;
  gap: 80px;
  margin-top: 80px;
}

.left-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
}

.logo-border {
  width: 520px;
  height: 520px;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  background: conic-gradient(
    #40b8ff 0deg 60deg,
    transparent 60deg 180deg,
    #00bf63 180deg 240deg,
    transparent 240deg 360deg
  );
  clip-path: circle(52% at center);
  transform: rotate(-60deg);
  opacity: 0.8;
}

.logo-wrapper {
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.logo {
  width: 200px;
  position: relative;
  z-index: 3;
}
.dots {
  position: absolute;
  top: 0;
  right: 0;
}

.right-hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 50%;
}
.special-text {
  font-family: "Sports World", sans-serif;
  font-size: 80px;
  color: #00bf63;
}
.text {
  font-size: 35px;
  text-align: left;
  color: white;
  font-weight: 600;
  max-width: 500px;
}
span {
  color: #40b8ff;
}
.circle {
  width: 200px;
  height: 200px;
  border: 40px solid #00bf63;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: -150px;
  right: -150px;
}
@media (max-width: 1240px) {
  .hero-section {
    gap: 60px;
  }
  .logo-border {
    width: 450px;
    height: 450px;
  }
  .logo-wrapper {
    width: 350px;
    height: 350px;
  }
  .special-text {
    font-size: 60px;
  }
  .text {
    font-size: 30px;
  }
  .circle {
    width: 150px;
    height: 150px;
    bottom: -120px;
    right: -120px;
    border: 30px solid #00bf63;
  }
}
@media (max-width: 850px) {
  .hero-section {
    gap: 40px;
  }
  .logo-border {
    width: 350px;
    height: 350px;
  }
  .logo-wrapper {
    width: 250px;
    height: 250px;
  }
  .logo {
    width: 150px;
  }
  .special-text {
    font-size: 50px;
  }
  .text {
    font-size: 25px;
  }
  .circle {
    width: 100px;
    height: 100px;
    bottom: -80px;
    right: -80px;
    border: 20px solid #00bf63;
  }
}
@media (max-width: 650px) {
  .hero-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 80px;
    margin-top: 80px;
  }
  .logo-border {
    width: 300px;
    height: 300px;
  }
  .logo-wrapper {
    width: 200px;
    height: 200px;
  }
  .logo {
    width: 100px;
  }
  .right-hero-section {
    align-items: center;
  }
  .special-text {
    font-size: 40px;
  }
  .text {
    font-size: 20px;
    text-align: center;
  }
}
</style>
