<template>
  <body class="body-mission" id="ourMission">
    <div class="left-side-body">
      <div class="title">Mission of the Association</div>
      <div class="line" />
      <div class="sub-title">
        Our mission is to connect children
        <span
          >without parental care, children with difficulties, and children from
          local communities
        </span>
        through <span class="span-two">sports</span>. The goal is not to create
        professional athletes but to educate them about essential life skills
        through activities they love. We aim to develop children's motor and
        intellectual skills through sports.
      </div>
    </div>
    <div class="right-side-body">
      <div class="top">
        <div class="kid-wrapper">
          <img
            class="kid-img"
            src="../../assets/OurMission/kid-one.png"
            alt="kid"
          />
        </div>
      </div>
      <div class="bottom">
        <div class="kids-wrapper">
          <img
            class="kids-img"
            src="../../assets/OurMission/kids.png"
            alt="kids"
          />
        </div>
      </div>
    </div>
    <DotsComponent class="dots" />
    <div class="circle" />
  </body>
</template>

<script>
import DotsComponent from "../SharedComponents/DotsComponent.vue";

export default {
  name: "OurMission",
  components: {
    DotsComponent,
  },
};
</script>

<style scoped>
.body-mission {
  height: 660px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 40px 40px;
  box-sizing: border-box;
  z-index: 3;
}
.left-side-body {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 50px;
  box-sizing: border-box;
  gap: 30px;
}
.title {
  font-size: 72px;
  color: #737373;
  font-weight: 600;
  line-height: 1;
  max-width: 600px;
}
.line {
  border: 2px solid #00bf63;
  width: 80px;
}

span {
  font-weight: 600;
}
.span-two {
  color: #40b8ff;
  text-decoration: underline;
}
.dots {
  position: absolute;
  top: 0%;
  left: 50%;
}
.sub-title {
  font-size: 18px;
}
.right-side-body {
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.top,
.bottom {
  height: 50%;
  width: 100%;
}
.kid-wrapper,
.kids-wrapper {
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.kid-img,
.kids-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.kid-wrapper {
  position: absolute;
  top: -70px;
  right: -50px;
}
.circle {
  width: 200px;
  height: 200px;
  border: 40px solid #00bf63;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: -150px;
  right: -150px;
}
@media (max-width: 1240px) {
  .body-mission {
    height: fit-content;
  }
  .kids-wrapper,
  .kid-wrapper {
    width: 300px;
    height: 300px;
  }
  .title {
    font-size: 62px;
  }
}
@media (max-width: 1024px) {
  .body-mission {
    flex-direction: column-reverse;
    padding: 40px 20px;
    gap: 20px;
  }
  .left-side-body {
    width: 100%;
    padding: 20px;
    gap: 10px;
    position: relative;
    z-index: 3;
  }
  .right-side-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .kid-wrapper {
    position: unset;
  }
  .kids-wrapper,
  .kid-wrapper {
    width: 250px;
    height: 250px;
  }
  .top,
  .bottom {
    height: fit-content;
    width: fit-content;
  }
  .dots {
    position: absolute;
    top: 0%;
    right: 50%;
  }
  .title {
    font-size: 52px;
  }
  .sub-title {
    font-size: 16px;
  }
  .circle {
    width: 150px;
    height: 150px;
    bottom: -120px;
    right: -120px;
    border: 30px solid #00bf63;
  }
}
@media (max-width: 850px) {
  .sub-title {
    font-size: 14px;
  }
  .circle {
    width: 100px;
    height: 100px;
    bottom: -80px;
    right: -80px;
    border: 20px solid #00bf63;
  }
  .kids-wrapper,
  .kid-wrapper {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 650px) {
  .sub-title {
    font-size: 12px;
  }
  .kids-wrapper,
  .kid-wrapper {
    width: 150px;
    height: 150px;
  }
  .left-side-body {
    padding: 20px 0px;
  }
  .title {
    font-size: 42px;
  }
}
</style>
