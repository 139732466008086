<template>
  <div class="footer">
    <div class="footer-body">
      <div class="logo-wrapper">
        <img
          class="footer-logo"
          src="../../assets/footer-logo.png"
          alt="footer logo"
        />
        <div class="text-logo">
          Connecting & Educating Children in Care Through Sports
        </div>
      </div>
      <div class="navigation-wrapper">
        <div class="navigation">
          <div class="nav-title">Navigation</div>
          <div>
            <div
              v-for="(item, index) in navItems"
              :key="index"
              class="nav-list hover-effect"
            >
              <template v-if="index < sectionIds.length">
                <span @click="handleNavigation(index)" class="no-decoration">
                  {{ item }}
                </span>
              </template>
              <template v-else>
                <router-link
                  :to="routes[index - sectionIds.length]"
                  class="no-decoration"
                  @click="scrollToTop"
                >
                  {{ item }}
                </router-link>
              </template>
            </div>
          </div>
        </div>
        <div class="policies">
          <div class="nav-title">Policies & Documents</div>
          <div>
            <div
              v-for="(policy, index) in policies"
              :key="index"
              class="nav-list hover-effect"
            >
              <a :href="policy.link" target="_blank" class="no-decoration">
                {{ policy.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="contact">
          <div class="nav-title">Get in Touch</div>
          <div>
            <div
              v-for="(item, index) in contactItems"
              :key="index"
              class="nav-list no-hover"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="quote-text">
        Educate,&nbsp;<span class="play">Play,&nbsp;</span>
        <span class="unite"> Unite</span>
      </div>
      <div class="line"></div>
      <div class="rights-icons-wrapper">
        <div class="rights-text">
          @2024-2025 UNITED Association. All rights reserved.
        </div>
        <div class="icons">
          <a href="mailto:info@united.ba" class="icon mail" aria-label="Email">
            <div class="icon mail"></div>
          </a>
          <a href="https://www.linkedin.com/company/united-ba">
            <div class="icon linkedin"></div>
          </a>
          <a href="https://www.instagram.com/united.org.ba">
            <div class="icon instagram"></div>
          </a>
          <a href="https://www.facebook.com/united.org.ba">
            <div class="icon facebook"></div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      navItems: [
        "What We Do",
        "Our Mission",
        "Our Impact",
        "Our Goal",
        "Achievements",
        "Contact",
      ],
      sectionIds: ["whatWeDo", "ourMission", "ourImpact", "ourGoal"],
      routes: ["/achievements", "/contact"],
      policies: [
        { name: "Privacy Policy", link: "/documents/privacy-policy.pdf" },
        { name: "Safeguarding", link: "/documents/safeguarding.pdf" },
      ],
      contactItems: [
        "info@united.ba",
        "Skenderija 31",
        "71000 Sarajevo",
        "Bosnia & Herzegovina",
      ],
    };
  },
  methods: {
    handleNavigation(index) {
      if (this.$route.path !== "/") {
        this.$router.push("/").then(() => {
          this.$nextTick(() => {
            this.scrollToSection(index);
          });
        });
      } else {
        this.scrollToSection(index);
      }
    },
    scrollToSection(index) {
      const sectionId = this.sectionIds[index];
      this.$nextTick(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    scrollToTop(event) {
      event.preventDefault();
      window.scrollTo({ top: 0, behavior: "auto" });
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Sports World";
  src: url("@/assets/fonts/SportsWorld.ttf") format("truetype");
}
.footer {
  height: fit-content;
  background-color: #030929;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 40px 20px 40px;
  position: relative;
  z-index: 1;
  gap: 80px;
}
.footer-body {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  gap: 30px;
}
.logo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-logo {
  width: 140px;
}
.text-logo {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  max-width: 220px;
  margin-left: 14px;
}
.navigation-wrapper {
  display: flex;
  gap: 80px;
}
.navigation,
.policies,
.contact {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.nav-title {
  font-size: 20px;
  font-weight: 700;
  color: #40b8ff;
}
.hover-effect:hover {
  color: #00bf63;
}
.nav-list {
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}
.footer-bottom {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.quote-text {
  color: #ffffff;
  font-family: "Sports World", sans-serif;
  font-size: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.play {
  color: #00bf63;
}
.unite {
  color: #40b8ff;
}
.line {
  width: 100%;
  height: 4px;
  background-color: #00bf63;
  border-radius: 20px;
}
.rights-text {
  color: #ffffff;
  text-decoration: none;
}
.rights-icons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

.icon {
  width: 35px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}
.icon.mail {
  background-image: url("../../assets/icons/mail-white.png");
}
.icon.mail:hover {
  background-image: url("../../assets/icons/mail.png");
}
.icon.linkedin {
  background-image: url("../../assets/icons/linkedin-white.png");
}
.icon.linkedin:hover {
  background-image: url("../../assets/icons/linkedin.png");
}
.icon.instagram {
  background-image: url("../../assets/icons/instagram-white.png");
}
.icon.instagram:hover {
  background-image: url("../../assets/icons/instagram.png");
}
.icon.facebook {
  background-image: url("../../assets/icons/facebook-white.png");
}
.icon.facebook:hover {
  background-image: url("../../assets/icons/facebook.png");
}
.icon:hover {
  transform: scale(1.1);
}
.no-hover:hover {
  color: inherit;
  cursor: default;
}
.no-decoration {
  text-decoration: none;
  color: inherit;
}
.no-decoration:hover {
  text-decoration: none;
}
@media (max-width: 1240px) {
  .text-logo,
  .nav-list {
    font-size: 16px;
  }
  .nav-title {
    font-size: 18px;
  }
}
@media (max-width: 1024px) {
  .footer {
    padding: 30px 30px 20px 30px;
    gap: 60px;
  }

  .text-logo,
  .nav-list {
    font-size: 14px;
  }
  .nav-title {
    font-size: 16px;
  }
}

@media (max-width: 850px) {
  .footer {
    padding: 20px;
    gap: 30px;
  }
  .footer {
    padding: 20px;
    gap: 30px;
  }
  .footer-logo {
    width: 120px;
  }
}
@media (max-width: 650px) {
  .footer-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .text-logo,
  .nav-list {
    font-size: 12px;
  }
  .nav-title {
    font-size: 14px;
  }
  .footer-logo {
    width: 100px;
  }
  .navigation-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
  .navigation,
  .policies,
  .contact {
    gap: 5px;
  }
  .quote-text {
    font-size: 20px;
  }
  .rights-text {
    font-size: 8px;
  }
  .icons {
    gap: 10px;
  }

  .icon {
    width: 25px;
    height: 25px;
  }
}
</style>
