<template>
  <div
    :class="[
      'header-body',
      { scrolled: isScrolled || localScrolled, fixed: isFixed },
    ]"
  >
    <a href="https://united.ba/">
      <img class="logo" src="../../assets/logo.png" alt="logo" />
    </a>
    <div :class="['navigation', { scrolled: isScrolled || localScrolled }]">
      <div
        v-for="(item, index) in navigationItems"
        :key="index"
        :class="[
          'text',
          { scrolled: isScrolled || localScrolled, active: isActive(index) },
        ]"
      >
        <template v-if="index < sectionIds.length">
          <span @click="handleNavigation(index)" class="no-decoration">
            {{ item }}
          </span>
        </template>
        <template v-else>
          <router-link
            :to="routes[index - sectionIds.length]"
            class="no-decoration"
            @click="scrollToTop"
          >
            {{ item }}
          </router-link>
        </template>
      </div>
    </div>
    <router-link to="/donations" class="button">DONATE</router-link>
    <img
      class="menu"
      src="../../assets/menu.png"
      alt="menu"
      @click="toggleMobileHeader"
    />

    <MobileHeader v-if="isMobileHeaderOpen" @close="toggleMobileHeader" />
  </div>
</template>

<script>
import MobileHeader from "../MobileHeader/MobileHeader.vue";

export default {
  components: {
    MobileHeader,
  },
  props: {
    isScrolled: {
      type: Boolean,
      default: false,
    },
    isFixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      navigationItems: [
        "What We Do",
        "Our Mission",
        "Our Impact",
        "Our Goal",
        "Achievements",
        "Contact",
      ],
      sectionIds: ["whatWeDo", "ourMission", "ourImpact", "ourGoal"],
      routes: ["/achievements", "/contact"],
      localScrolled: false,
      activeSection: null,
      isMobileHeaderOpen: false, // Novo stanje za otvaranje MobileHeader.vue
    };
  },
  computed: {
    activeRoute() {
      return this.$route.path;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.observeSections();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.localScrolled = window.scrollY > 20;
    },
    handleNavigation(index) {
      if (this.$route.path !== "/") {
        this.$router.push("/").then(() => {
          this.$nextTick(() => {
            this.scrollToSection(index);
          });
        });
      } else {
        this.scrollToSection(index);
      }
    },
    scrollToSection(index) {
      const sectionId = this.sectionIds[index];
      this.$nextTick(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    scrollToTop(event) {
      event.preventDefault();
      window.scrollTo({ top: 0, behavior: "auto" });
    },
    observeSections() {
      const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.activeSection = entry.target.id;
          }
        });
      }, observerOptions);

      this.sectionIds.forEach((id) => {
        const section = document.getElementById(id);
        if (section) {
          observer.observe(section);
        }
      });
    },
    isActive(index) {
      if (index < this.sectionIds.length) {
        return this.activeSection === this.sectionIds[index];
      } else {
        return this.activeRoute === this.routes[index - this.sectionIds.length];
      }
    },
    toggleMobileHeader() {
      this.isMobileHeaderOpen = !this.isMobileHeaderOpen;
    },
  },
};
</script>

<style scoped>
.header-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 10px 80px;
  box-sizing: border-box;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.header-body.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}
.header-body.scrolled {
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.logo {
  width: 60px;
}
.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  color: white;
  transition: color 0.3s ease-in-out;
}
.navigation.scrolled {
  color: #292c47;
}
.text {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  color: white;
  transition: color 0.3s ease-in-out;
  text-align: center;
}
.text.scrolled {
  color: #292c47;
}
.text:hover,
.text.active {
  color: #00bf63 !important;
}
.button {
  width: 151px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  border-radius: 30px;
  color: #ffffff;
  background-color: #00bf63;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  text-decoration: none;
}
.button:hover {
  background-color: #137847;
}
.no-decoration {
  text-decoration: none;
  color: inherit;
}
.no-decoration:hover {
  text-decoration: none;
}

.menu {
  width: 30px;
  display: none;
}
@media (max-width: 1240px) {
  .header-body {
    padding: 10px 30px;
  }
  .logo {
    width: 50px;
  }
  .navigation {
    gap: 20px;
  }
  .text {
    font-size: 14px;
  }
  .button {
    width: 121px;
    height: 40px;
    font-size: 14px;
  }
}
@media (max-width: 850px) {
  .header-body {
    padding: 10px 20px;
  }
  .logo {
    width: 40px;
  }
  .navigation {
    gap: 10px;
  }
  .text {
    font-size: 12px;
  }
  .button {
    width: 100px;
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  .header-body {
    padding: 10px 20px;
  }
  .navigation {
    display: none;
  }
  .down-img {
    display: block;
    width: 35px;
  }
  .menu {
    display: block;
  }
}
</style>
