<template>
  <body>
    <DotsComponent class="dots" />
    <div class="achievements-body">
      <div class="icons">
        <img
          class="icon"
          src="../../assets/Achievements/uefa-foundation.jpeg"
          alt="uefa"
        />
        <img
          class="icon"
          src="../../assets/Achievements/hope-for-mundial.jpeg"
          alt="hopeformundial"
        />
      </div>
      <div class="title">World Championship for Children in Care:</div>
      <div class="text">
        In collaboration with SOS Children’s Villages, we proudly led the Bosnia
        and Herzegovina team to <span class="victory">victory </span> at the
        World Football Championship for Children in Care, held in Poland. This
        prestigious tournament, supported by UEFA Foundation, brought together
        teams from around the world, offering young players a unique opportunity
        to showcase their talent, determination, and love for the game.
        <br /><br />
        Our team was a remarkable blend of five children without parental care
        and five children from local communities, who actively participate in
        programs, proving that football is more than just a sport - it is a
        bridge that connects and unites.
      </div>
      <div class="pictures-wrapper">
        <div class="picture-wrapper-one"></div>
        <div class="picture-wrapper-two"></div>
        <div class="picture-wrapper-three"></div>
        <div class="picture-wrapper-four"></div>
        <div class="picture-wrapper-five"></div>
        <div class="picture-wrapper-six"></div>
      </div>
      <div class="text">
        Sport transforms lives. At the World Football Championship for Children
        in Care, we proved that every child deserves a chance to grow, belong,
        and succeed. By bringing together children without parental care and
        those from local communities, we showed the power of teamwork, support,
        and opportunity. <br /><br />
        This is more than football - it’s about confidence, unity, and a
        brighter future.
      </div>
      <div class="main-title">
        That is what <span class="united">UNITED</span> Association is about!
      </div>
    </div>
    <div class="circle" />
  </body>
</template>

<script>
import DotsComponent from "../SharedComponents/DotsComponent.vue";

export default {
  name: "AppAchievements",
  components: {
    DotsComponent,
  },
};
</script>

<style scoped>
body {
  color: black;
  position: relative;
  padding: 40px 40px 80px 40px;
}
.achievements-body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.dots {
  position: absolute;
  top: -50px;
  left: 0;
}
.icons {
  display: flex;
  gap: 30px;
}
.icon {
  height: 110px;
}
.title {
  font-size: 20px;
  color: #40b8ff;
  font-weight: 600;
}
.text {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 50px;
}
.victory {
  color: #00bf63;
}
.pictures-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 50px;
}
.picture-wrapper-one,
.picture-wrapper-two,
.picture-wrapper-three,
.picture-wrapper-four,
.picture-wrapper-five,
.picture-wrapper-six {
  width: 350px;
  height: 350px;
  border-radius: 20px;

  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: #030929 1px 0 10px;
}
.picture-wrapper-one {
  background-image: url("../../assets/Achievements/bosna-1.jpeg");
  background-position: 90% 0%;
}
.picture-wrapper-two {
  background-image: url("../../assets/Achievements/bosna-2.jpeg");
  background-position: 50% 0%;
}
.picture-wrapper-three {
  background-image: url("../../assets/Achievements/bosna-3.jpeg");
  background-position: 20% 0%;
}
.picture-wrapper-four {
  background-image: url("../../assets/Achievements/bosna-4.jpeg");
  background-position: 50% 0%;
}
.picture-wrapper-five {
  background-image: url("../../assets/Achievements/bosna-5.jpeg");
  background-position: 50% 0%;
}
.picture-wrapper-six {
  background-image: url("../../assets/Achievements/bosna-6.jpeg");
  background-position: 90% 0%;
}
.main-title {
  margin-top: 30px;
  font-size: 28px;
  color: #40b8ff;
  font-weight: 700;
  text-align: center;
}
.united {
  color: #00bf63;
}
.circle {
  width: 200px;
  height: 200px;
  border: 40px solid #030929;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: -150px;
  left: -150px;
}

@media (max-width: 1240px) {
  .picture-wrapper-one,
  .picture-wrapper-two,
  .picture-wrapper-three,
  .picture-wrapper-four,
  .picture-wrapper-five,
  .picture-wrapper-six {
    width: 260px;
    height: 260px;
    border-radius: 20px;

    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: #030929 1px 0 7px;
  }
  .title,
  .text {
    font-size: 18px;
  }
  .main.title {
    font-size: 25px;
  }
}
@media (max-width: 1024px) {
  .pictures-wrapper {
    gap: 30px;
    margin-top: 20px;
  }
  .picture-wrapper-one,
  .picture-wrapper-two,
  .picture-wrapper-three,
  .picture-wrapper-four,
  .picture-wrapper-five,
  .picture-wrapper-six {
    width: 220px;
    height: 220px;
  }
  .title,
  .text {
    font-size: 16px;
    margin-top: 0px;
  }
  .main-title {
    font-size: 22px;
  }
  .circle {
    width: 150px;
    height: 150px;
    border: 30px solid #030929;
    bottom: -150px;
    left: -150px;
  }
}
@media (max-width: 850px) {
  body {
    padding: 20px 20px 40px 20px;
  }
  .dots {
    display: none;
  }
  .icons {
    gap: 20px;
  }
  .icon {
    height: 90px;
  }
  .pictures-wrapper {
    gap: 20px;
    margin-top: 0px;
  }
  .picture-wrapper-one,
  .picture-wrapper-two,
  .picture-wrapper-three,
  .picture-wrapper-four,
  .picture-wrapper-five,
  .picture-wrapper-six {
    width: 200px;
    height: 200px;
  }
  .title,
  .text {
    font-size: 14px;
    margin-top: 18px;
  }
  .main-title {
    font-size: 20px;
    margin-top: 5px;
  }
  .circle {
    width: 100px;
    height: 100px;
    border: 20px solid #030929;
    bottom: -90px;
    left: -90px;
  }
}
@media (max-width: 650px) {
  .icons {
    gap: 10px;
  }
  .icon {
    height: 60px;
  }
  .pictures-wrapper {
    gap: 20px;
    margin-top: 0px;
  }

  .picture-wrapper-one,
  .picture-wrapper-two,
  .picture-wrapper-three,
  .picture-wrapper-four,
  .picture-wrapper-five,
  .picture-wrapper-six {
    width: 270px;
    height: 270px;
  }
  .title,
  .text {
    font-size: 12px;
    margin-top: 0px;
  }
  .circle {
    width: 100px;
    height: 100px;
    border: 20px solid #030929;
    bottom: -90px;
    left: -90px;
  }
}
</style>
