<template>
  <div>
    <AppContact />
  </div>
</template>

<script>
import AppContact from "../components/Contact/AppContact.vue";

export default {
  components: {
    AppContact,
  },
};
</script>
