<template>
  <body class="learning-values-body">
    <DotsComponent class="dots" />
    <div class="circle-green"></div>
    <div class="circle"></div>
    <div class="learning-values-title">
      <div class="title">Learning Values</div>
      <div class="quote">
        {{ quote }}
      </div>
    </div>
    <div class="content-wrapper">
      <div class="icons-wrapper">
        <div v-for="(icon, index) in icons" :key="index" class="single-icon">
          <img class="icon" :src="icon.src" :alt="icon.alt" />
          <div class="icon-text">{{ icon.text }}</div>
        </div>
      </div>
      <div class="learning-values-text">
        The <span>UNITED</span> Association continuously monitors and evaluates
        children's development through sports and education, providing them with
        the opportunity to acquire essential life skills. Our goal is to enable
        children from diverse backgrounds to grow physically, emotionally, and
        intellectually through sports activities, games, and educational
        programs. Through our programs, children are expected to improve:
      </div>
    </div>
  </body>
</template>

<script>
import DotsComponent from "../SharedComponents/DotsComponent.vue";
import internalMotivation from "../../assets/LearningValues/internal-motivation.png";
import integrity from "../../assets/LearningValues/integrity.png";
import resilience from "../../assets/LearningValues/resilience.png";
import emotionalIntelligence from "../../assets/LearningValues/emotional-intelligence.png";
import problemSolving from "../../assets/LearningValues/problem-solving.png";

export default {
  name: "LearningValues",
  components: {
    DotsComponent,
  },
  props: {
    quote: {
      type: String,
      default:
        "Intelligence is an important factor in how the individual responds to sports education",
    },
  },
  data() {
    return {
      icons: [
        {
          src: internalMotivation,
          alt: "Internal Motivation",
          text: "Internal Motivation",
        },
        { src: integrity, alt: "Integrity", text: "Integrity" },
        { src: resilience, alt: "Resilience", text: "Resilience" },
        {
          src: emotionalIntelligence,
          alt: "Emotional Intelligence",
          text: "Emotional Intelligence",
        },
        {
          src: problemSolving,
          alt: "Problem Solving",
          text: "Problem Solving",
        },
      ],
    };
  },
};
</script>

<style scoped>
.learning-values-body {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  position: relative;
  flex-direction: column;
}
.learning-values-title {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 62px;
  color: #737373;
  font-weight: 700;
  line-height: 1;
  max-width: 600px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.quote {
  font-size: 18px;
  color: #737373;
  text-transform: uppercase;
  max-width: 600px;
  text-align: center;
  position: relative;
  z-index: 1;
}
.dots {
  position: absolute;
  top: 0;
  left: 0;
}
.circle-green {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #00bf63;
  margin: 20px 20px 0px 0px;
}
.circle {
  width: 200px;
  height: 200px;
  border: 40px solid #00bf63;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: -150px;
  right: -150px;
}
.icons-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.single-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.icon {
  width: 100px;
}
.icon-text {
  font-size: 18px;
  font-weight: 600;
}
.learning-values-text {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0px 80px;
  position: relative;
  z-index: 3;
}
span {
  font-weight: 600;
  color: #00bf63;
}

@media (max-width: 1240px) {
  .title {
    font-size: 62px;
  }
}

@media (max-width: 1024px) {
  .learning-values-body {
    gap: 40px;
  }
  .title {
    font-size: 52px;
  }
  .quote,
  .learning-values-text,
  .icon-text {
    font-size: 16px;
  }
  .single-icon {
    gap: 20px;
  }
  .icon {
    width: 80px;
  }
  .dots {
    display: none;
  }
  .circle-green {
    width: 60px;
    height: 60px;
    margin: 20px 20px 0px 0px;
  }
  .circle {
    width: 150px;
    height: 150px;
    bottom: -120px;
    right: -120px;
    border: 30px solid #00bf63;
  }
}
@media (max-width: 850px) {
  .learning-values-body {
    gap: 20px;
  }
  .quote,
  .learning-values-text,
  .icon-text {
    font-size: 14px;
  }
  .single-icon {
    gap: 10px;
  }
  .icon {
    width: 60px;
  }
  .circle-green {
    width: 50px;
    height: 50px;
  }
  .circle {
    width: 100px;
    height: 100px;
    bottom: -80px;
    right: -80px;
    border: 20px solid #00bf63;
  }
}
@media (max-width: 650px) {
  .learning-values-body {
    padding: 0px 20px;
    box-sizing: border-box;
    gap: 20px;
  }

  .learning-values-text {
    margin: 0;
    order: 1;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 42px;
  }
  .quote,
  .learning-values-text,
  .icon-text {
    font-size: 12px;
  }
  .single-icon {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .icons-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    order: 2;
  }

  .icon {
    width: 60px;
  }
  .circle-green {
    display: none;
  }
}
</style>
